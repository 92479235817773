import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { red } from '../constants';

const Header = styled.h1`
  margin: 2rem auto 0;
  text-align: center;
  font-size: 3rem;
`;

const CTA = styled(Link)`
  padding: 12px;
  margin: 1rem 0 8rem;
  font-weight: 500;
  font-size: 1.25rem;
  color: ${red};
  display: block;
  text-align: center;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header>Page Not Found</Header>
    <CTA>Go to homepage</CTA>
  </Layout>
);

export default NotFoundPage;
